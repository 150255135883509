import React, { useState, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close'
import { withRouter } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import NumberFormat from 'react-number-format';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  IconButton,
  Button,
  TextField,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

function SnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      prefix="¥"
      allowLeadingZeros="false"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SectionEditDetails = props => {
  const { className, staticContext, id, ...rest } = props;
  const classes = useStyles();
  var _isMounted = false;

  const [values, setValues] = useState({
    name: '',
    dPoint: 0,
    mPoint: 0,
    sPoint: 0,
    yPoint: 0,
    color: '',
    target1: 0,
    target2: 0,
    target3: 0,
    target4: 0,
    target5: 0,
    target6: 0,
    target7: 0,
    target8: 0,
    target9: 0,
    target10: 0,
    target11: 0,
    target12: 0
  });

  useEffect(() => {
    _isMounted = true;
    const fetchData = () => {
      axios.get(
        '/api/section/' + id
      ).then(function(resultSection) {
        if (resultSection.data.success && resultSection.data.success !== undefined) {
          if(_isMounted) {
            setValues({
              ...values,
              name: resultSection.data.data.name,
              dPoint: resultSection.data.data.dPoint,
              mPoint: resultSection.data.data.mPoint,
              sPoint: resultSection.data.data.sPoint,
              yPoint: resultSection.data.data.yPoint,
              color: resultSection.data.data.color,
              target1: resultSection.data.data.target1,
              target2: resultSection.data.data.target2,
              target3: resultSection.data.data.target3,
              target4: resultSection.data.data.target4,
              target5: resultSection.data.data.target5,
              target6: resultSection.data.data.target6,
              target7: resultSection.data.data.target7,
              target8: resultSection.data.data.target8,
              target9: resultSection.data.data.target9,
              target10: resultSection.data.data.target10,
              target11: resultSection.data.data.target11,
              target12: resultSection.data.data.target12
            });
          }
        }
      });
    }

    fetchData();

    return () => {
      _isMounted = false;
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [eopen, setEopen] = useState(false);
  const [cmessage, setCmessage] = useState();

  function handleClick() {
    setOpen(true);
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  function handleErrorClick() {
    setEopen(true);
  }

  function handleErrorClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setEopen(false);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlePointChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value
    });
  };

  const handleUpdateSection = async () => {
    const payload = values;

    try {
      const result = await axios.put (
        '/api/section/' + id,
        payload
      );
    
      if (result.data.success && result.data.success !== undefined) {
        setCmessage(result.data.message);
        handleClick();
        props.history.push("/sections");
      }
      else {
        setCmessage(result.data.message);
        handleErrorClick();
      }
    } catch (error) {
      setCmessage("セクション作成されていません!");
      handleErrorClick();
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader=""
          title="セクション編集する"
        />
        <Divider />
        <CardContent>
        <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="名前"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="金銭"
                margin="dense"
                name="dPoint"
                required
                value={values.dPoint}
                variant="outlined"
                onChange={handlePointChange('dPoint')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="色"
                margin="dense"
                name="color"
                onChange={handleChange}
                value={values.color}
                variant="outlined"
                placeholder="例えば: 060606"
                helperText="入力する必要はありません"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="毎月の目標金銭"
                margin="dense"
                name="mPoint"
                required
                value={values.mPoint}
                variant="outlined"
                onChange={handlePointChange('mPoint')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="三ヶ月の目標金銭"
                margin="dense"
                name="sPoint"
                required
                value={values.sPoint}
                variant="outlined"
                onChange={handlePointChange('sPoint')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="今年の目標金銭"
                margin="dense"
                name="yPoint"
                required
                value={values.yPoint}
                variant="outlined"
                onChange={handlePointChange('yPoint')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <Card
                {...rest}
                className={clsx(classes.root, className)}
              >
                <CardHeader
                  subheader="12月1月2月の目標金銭"
                  title="1Q"
                />
                <CardContent>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="12月"
                      margin="dense"
                      name="target12"
                      value={values.target12}
                      variant="outlined"
                      onChange={handlePointChange('target12')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="1月"
                      margin="dense"
                      name="target1"
                      value={values.target1}
                      variant="outlined"
                      onChange={handlePointChange('target1')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="2月"
                      margin="dense"
                      name="target2"
                      value={values.target2}
                      variant="outlined"
                      onChange={handlePointChange('target2')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <Card
                {...rest}
                className={clsx(classes.root, className)}
              >
                <CardHeader
                  subheader="3月4月5月の目標金銭"
                  title="2Q"
                />
                <CardContent>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="3月"
                      margin="dense"
                      name="target3"
                      value={values.target3}
                      variant="outlined"
                      onChange={handlePointChange('target3')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="4月"
                      margin="dense"
                      name="target4"
                      value={values.target4}
                      variant="outlined"
                      onChange={handlePointChange('target4')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="5月"
                      margin="dense"
                      name="target5"
                      value={values.target5}
                      variant="outlined"
                      onChange={handlePointChange('target5')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <Card
                {...rest}
                className={clsx(classes.root, className)}
              >
                <CardHeader
                  subheader="6月7月8月の目標金銭"
                  title="3Q"
                />
                <CardContent>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="6月"
                      margin="dense"
                      name="target6"
                      value={values.target6}
                      variant="outlined"
                      onChange={handlePointChange('target6')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="7月"
                      margin="dense"
                      name="target7"
                      value={values.target7}
                      variant="outlined"
                      onChange={handlePointChange('target7')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="8月"
                      margin="dense"
                      name="target8"
                      value={values.target8}
                      variant="outlined"
                      onChange={handlePointChange('target8')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <Card
                {...rest}
                className={clsx(classes.root, className)}
              >
                <CardHeader
                  subheader="9月10月11月の目標金銭"
                  title="4Q"
                />
                <CardContent>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="9月"
                      margin="dense"
                      name="target9"
                      value={values.target9}
                      variant="outlined"
                      onChange={handlePointChange('target9')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="10月"
                      margin="dense"
                      name="target10"
                      value={values.target10}
                      variant="outlined"
                      onChange={handlePointChange('target10')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="11月"
                      margin="dense"
                      name="target11"
                      value={values.target11}
                      variant="outlined"
                      onChange={handlePointChange('target11')}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component={CustomRouterLink}
            to="/sections"
            >
              <CloseIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
              閉じる
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleUpdateSection}
            >
              <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
              保存
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <SnackbarContentWrapper
              onClose={handleClose}
              variant="success"
              message={cmessage}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={eopen}
            autoHideDuration={6000}
            onClose={handleErrorClose}
          >
            <SnackbarContentWrapper
              onClose={handleErrorClose}
              variant="error"
              message={cmessage}
            />
          </Snackbar>
        </CardActions>
      </form>
    </Card>
  );
};

SectionEditDetails.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};

export default withRouter(SectionEditDetails);
