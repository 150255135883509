import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Fade } from '@material-ui/core';

import {
  MonthlySales,
  YearlySales,
  MonthlyCompletedSales,
  YearlyCompletedSales
} from './components';

import socketIOClient from "socket.io-client";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
}));

const socket = socketIOClient('/');

const Dashboard = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        item={true}
        direction='row'
        justify="center"
        alignItems="baseline"
        spacing={2}
        xs={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid
          container
          item={true}
          direction='row'
          justify="center"
          alignItems="baseline"
          spacing={1}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Fade
            in={true}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={12}
              xl={12}
            >
              <MonthlySales socket={socket}/>
            </Grid>
          </Fade>
          <Fade
            in={true}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={12}
              xl={12}
            >
              <MonthlyCompletedSales socket={socket}/>
            </Grid>
          </Fade>
        </Grid>
        <Grid
          container
          item={true}
          direction='row'
          justify="center"
          alignItems="baseline"
          spacing={1}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Fade
            in={true}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={12}
              xl={12}
            >
              <YearlySales socket={socket}/>
            </Grid>
          </Fade>
          <Fade
            in={true}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={12}
              xl={12}
            >
              <YearlyCompletedSales socket={socket}/>
            </Grid>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
