import React, { useState, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close'
import { withRouter } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  IconButton,
  Button,
  TextField,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';
import { types } from 'helpers'

const useStyles = makeStyles(theme => ({
  root: {},
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

function SnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const UserEditDetails = props => {
  const { className, staticContext, id, ...rest } = props;
  const classes = useStyles();
  var _isMounted = false;

  const [values, setValues] = useState({
    lastname: '',
    firstname: '',
    type: '',
    uPoint: 0,
    email: '',
    password: '',
    section: '',
    sections: []
  });

  useEffect(() => {
    _isMounted = true;
    const fetchData = () => {
      axios.get(
        '/api/sections'
      ).then(function(resultSections) {
        axios.get(
          '/api/user/' + id
        ).then(function(resultUser) {
          if (resultUser.data.success && resultUser.data.success !== undefined) {
            if(_isMounted) {
              setValues({
                ...values,
                lastname: resultUser.data.data.lastname,
                firstname: resultUser.data.data.firstname,
                uPoint: resultUser.data.data.uPoint,
                type: resultUser.data.data.type,
                email: resultUser.data.data.email,
                password: resultUser.data.data.password,
                section: resultUser.data.data.section,
                sections: resultSections.data.data
              });
            }
          }
        });
      });
    }

    fetchData();

    return () => {
      _isMounted = false;
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [eopen, setEopen] = useState(false);
  const [cmessage, setCmessage] = useState();

  function handleClick() {
    setOpen(true);
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  function handleErrorClick() {
    setEopen(true);
  }

  function handleErrorClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setEopen(false);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUpdateUser = async () => {
    const payload = values;

    try {
      const result = await axios.put (
        '/api/user/' + id,
        payload
      );
    
      if (result.data.success && result.data.success !== undefined) {
        setCmessage(result.data.message);
        handleClick();
        props.history.push("/users");
      }
      else {
        setCmessage(result.data.message);
        handleErrorClick();
      }
    } catch (error) {
      setCmessage("ユーザー作成されていません!");
      handleErrorClick();
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader=""
          title="ユーザー編集する"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="苗字"
                margin="dense"
                name="lastname"
                onChange={handleChange}
                required
                value={values.lastname}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="セクション"
                margin="dense"
                name="section"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={values.section}
              >
                {values.sections.map(option => (
                  <option
                    key={option.name}
                    value={option._id}
                  >
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="名"
                margin="dense"
                name="firstname"
                onChange={handleChange}
                required
                value={values.firstname}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="ユーザータイプ"
                margin="dense"
                name="type"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={values.type}
              >
                {types.map(option => (
                  <option
                    key={option.key}
                    value={option.key}
                  >
                    {option.value}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="メールアドレス"
                name="email"
                type="email"
                margin="dense"
                autoComplete="email"
                helperText="入力する必要はありません"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="パスワード"
                margin="dense"
                name="password"
                type="password"
                helperText="入力する必要はありません"
                onChange={handleChange}
                value={values.password}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component={CustomRouterLink}
            to="/users"
            >
              <CloseIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
              閉じる
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleUpdateUser}
            >
              <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
              保存
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <SnackbarContentWrapper
              onClose={handleClose}
              variant="success"
              message={cmessage}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={eopen}
            autoHideDuration={6000}
            onClose={handleErrorClose}
          >
            <SnackbarContentWrapper
              onClose={handleErrorClose}
              variant="error"
              message={cmessage}
            />
          </Snackbar>
        </CardActions>
      </form>
    </Card>
  );
};

UserEditDetails.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};

export default withRouter(UserEditDetails);
