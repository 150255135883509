import React, { useState, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/ja';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SvgIcon from '@material-ui/core/SvgIcon';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: 0,
    minHeight: 700
  },
  inner: {
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  customHover: {
    '&:hover': {
      backgroundColor: "#FFF9C4 !important",
   }
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 22,
    }
  },
  body: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 24,
    }
  }
}))(TableCell);

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

const CompletedOrders = props => {
  const { className, socket, ...rest } = props;

  const classes = useStyles();

  const [response, setResponse] = useState({ data:[] });
  const [permission, setPermission] = useState('member');
  const isHeightMax = useMediaQuery('(min-height:1350px)');
  const permissionList = ['admin', 'manager'];
  var _isMounted = false;

  useEffect(() => {
    _isMounted = true;
    const getInitialData = async () => {
      let result = { data: [] };

      try {
        result = await axios.get(
          '/api/orders/completed'
        );
      } catch (err) {
        result = { data: [] };
      }

      if(_isMounted) {
        if (result.data !== undefined && result.data.data !== undefined) {
          setResponse(result.data);
        }
        else {
          setResponse({ data: [] });
        }
      }
    };

    let token = Cookies.get('token');
    if (token) {
      let tokenData = jwt_decode(token);
      setPermission(tokenData.type);
    }

    socket.on("change_data", function() {
      getInitialData();
    });

    getInitialData();

    return () => {
      _isMounted = false;
      socket.off("change_data");
    };
  }, []);

  const numberFormat = (value) =>
    new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY'
    }).format(value);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          null
        }
        title={"当月完了速報"}
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>セクション</StyledTableCell>
                <StyledTableCell>名前</StyledTableCell>
                <StyledTableCell>お客様</StyledTableCell>
                <StyledTableCell>案件</StyledTableCell>
                <StyledTableCell>請求金額</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.data.map(order => (
                <TableRow
                  hover
                  key={order._id}
                  className={classes.customHover}
                >
                  <StyledTableCell>
                    <span
                      style={order.section.color ? {color: "#" + order.section.color} : {color: "#689f38"}}>
                      <b>{order.section.name}</b>
                    </span>
                  </StyledTableCell>
                  <StyledTableCell><b>{order.name.lastname}</b></StyledTableCell>
                  <StyledTableCell>{order.client}</StyledTableCell>
                  <StyledTableCell>{order.order}</StyledTableCell>
                  <StyledTableCell>
                    <b>
                      {numberFormat(order.bill)}
                    </b>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        {permissionList.includes(permission) ?
        <Button
          color="primary"
          size="small"
          variant="text"
          component={CustomRouterLink}
          to="/orders"
        >
          全部見る <ArrowRightIcon />
        </Button> : null}
      </CardActions>
    </Card>
  );
};

CompletedOrders.propTypes = {
  className: PropTypes.string,
  socket: PropTypes.object
};

export default CompletedOrders;
