import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Fade } from '@material-ui/core';

import { OrderEditDetails } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const OrderEdit = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Fade
          in={true}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <OrderEditDetails id={props.match.params.id}/>
          </Grid>
        </Fade>
      </Grid>
    </div>
  );
};

export default OrderEdit;
