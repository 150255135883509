import React, { useState, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import { lighten, makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';

import { getInitials } from 'helpers';

function PointIcon(props) {
  return (
    <SvgIcon {...props}
      viewBox="0,0,64,64"
      shapeRendering="geometricPrecision"
      fontSize="inherit">
      <g><path d="M36.135,23H27v9h9.135c2.566,0,4.648-1.932,4.648-4.499C40.783,24.932,38.701,23,36.135,23z" fill="#CC4521"/><path d="M32,1C14.88,1,1,14.879,1,32s13.88,31,31,31c17.119,0,31-13.879,31-31S49.119,1,32,1z M36.135,38H27v10h-6   V17h15.135c5.991,0,10.85,4.508,10.85,10.501C46.984,33.492,42.126,38,36.135,38z" fill="#D8532B"/><path d="M43.292,3.131c3.861,5.112,6.117,11.28,6.117,17.922c0,17.655-15.938,31.969-35.602,31.969   c-1.65,0-3.273-0.109-4.865-0.305C14.618,59.029,22.844,63,32,63c17.119,0,31-13.879,31-31C63,18.865,54.827,7.646,43.292,3.131z" fill="#CC4521"/><path d="M40.604,28.501c0.104-0.391,0.179-0.61,0.179-1.031c0-2.569-2.082-4.471-4.648-4.471H27v2h9.135   C38.279,25,40.069,26.524,40.604,28.501z" fill="#FC732D"/><g><path d="M36.135,38H27v3h9.135c5.991,0,10.85-5.047,10.85-11.038c0-0.411-0.028-1.009-0.071-1.406    C46.31,33.977,41.717,38,36.135,38z" fill="#FC732D"/><path d="M32,3.422c16.714,0,30.333,13.229,30.97,29.789C62.983,32.808,63,32.407,63,32C63,14.879,49.119,1,32,1    C14.88,1,1,14.879,1,32c0,0.407,0.016,0.808,0.03,1.211C1.667,16.651,15.285,3.422,32,3.422z" fill="#FC732D"/><rect fill="#FC732D" height="2" width="6" x="21" y="48"/></g></g>
    </SvgIcon>
  );
}

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  header: {
    backgroundColor: '#FFEB3B'
  },
  customHover: {
    "&.Mui-selected": {
      backgroundColor: "#FFF9C4 !important",
    },
    '&:hover': {
      backgroundColor: "#FFF9C4 !important",
    }
  },
  customFloat: {
    float: 'left',
    paddingRight: '10px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  }
}));

function SnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const SectionsTable = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [selectedSections, setSelectedSections] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [page, setPage] = useState(0);
  const [sections, setSections] = useState([]);
  const month = new Date().getMonth() + 1;
  var _isMounted = false;

  useEffect(() => {
    _isMounted = true;
    const fetchData = async () => {
      const result = await axios(
        '/api/sections/all'
      );

      if(_isMounted) {
        setSections(result.data.data);
      }
    }

    fetchData();

    return () => {
      _isMounted = false;
    };
  }, []);

  const handleSelectAll = event => {
    let selectedSections;

    if (event.target.checked) {
      selectedSections = sections.map(section => section._id);
    } else {
      selectedSections = [];
    }

    setSelectedSections(selectedSections);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedSections.indexOf(id);
    let newSelectedSections = [];

    if (selectedIndex === -1) {
      newSelectedSections = newSelectedSections.concat(selectedSections, id);
    } else if (selectedIndex === 0) {
      newSelectedSections = newSelectedSections.concat(selectedSections.slice(1));
    } else if (selectedIndex === selectedSections.length - 1) {
      newSelectedSections = newSelectedSections.concat(selectedSections.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedSections = newSelectedSections.concat(
        selectedSections.slice(0, selectedIndex),
        selectedSections.slice(selectedIndex + 1)
      );
    }

    setSelectedSections(newSelectedSections);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const [eopen, setEopen] = useState(false);
  const [cmessage, setCmessage] = useState();

  function handleClick() {
    setOpen(true);
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  function handleErrorClick() {
    setEopen(true);
  }

  function handleErrorClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setEopen(false);
  }

  const handleDeleteClick = async (sId) => {
    try {
      const result = await axios.delete (
        '/api/section/' + sId
      );
    
      if (result.data.success && result.data.success !== undefined) {
        setSections(
          sections.filter(section => section._id !== sId),
        );

        setCmessage(result.data.message);
        handleClick();
      }
      else {
        setCmessage(result.data.message);
        handleErrorClick();
      }
    } catch (error) {
      setCmessage("セクション削除されていません!");
      handleErrorClick();
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY'
    }).format(value);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Toolbar
          className={clsx(classes.root, classes.header, {
            [classes.highlight]: selectedSections.length > 0,
          })}
        >
          <div className={classes.title}>
            {selectedSections.length > 0 ? (
              <Typography color="inherit" variant="subtitle1">
                {selectedSections.length} 選択された
              </Typography>
            ) : (
              <Typography variant="h5" id="tableTitle">
                セクション
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
        </Toolbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <SnackbarContentWrapper
            onClose={handleClose}
            variant="success"
            message={cmessage}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={eopen}
          autoHideDuration={6000}
          onClose={handleErrorClose}
        >
          <SnackbarContentWrapper
            onClose={handleErrorClose}
            variant="error"
            message={cmessage}
          />
        </Snackbar>
        <div className={classes.inner}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  {/* <Checkbox
                    checked={selectedSections.length === sections.length}
                    color="primary"
                    indeterminate={
                      selectedSections.length > 0 &&
                      selectedSections.length < sections.length
                    }
                    onChange={handleSelectAll}
                  /> */}
                </TableCell>
                <TableCell>名前</TableCell>
                <TableCell>金銭</TableCell>
                <TableCell>毎月の目標金銭</TableCell>
                <TableCell>今月の目標金銭</TableCell>
                <TableCell>三ヶ月の目標金銭</TableCell>
                <TableCell>今年の目標金銭</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sections.length > 0 ? sections.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(section => (
                <TableRow
                  className={clsx(classes.tableRow, classes.customHover)}
                  hover
                  key={section._id}
                  selected={selectedSections.indexOf(section._id) !== -1}
                >
                  <TableCell padding="checkbox">
                    {/* <Checkbox
                      checked={selectedSections.indexOf(section._id) !== -1}
                      color="primary"
                      onChange={event => handleSelectOne(event, section._id)}
                      value="true"
                    /> */}
                  </TableCell>
                  <TableCell>
                    <span
                      style={section.color ? {color: "#" + section.color} : {color: "#689f38"}}>
                      <b>
                        {section.name}
                      </b>
                    </span>
                  </TableCell>
                  <TableCell>
                    <b>
                      {numberFormat(section.dPoint)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {numberFormat(section.mPoint)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {numberFormat(section["target" + (month)])}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {numberFormat(section.sPoint)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {numberFormat(section.yPoint)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <div className={classes.customFloat}>
                      <Button
                        color="primary"
                        variant="outlined"
                        component={CustomRouterLink}
                        to={`/section-edit/${section._id}`}
                      >
                        編集する
                      </Button>
                    </div>
                    <div className={classes.customFloat}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={event => handleDeleteClick(section._id) }
                      >
                        削除する
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              )) : <TableRow/>}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={sections.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 12, 20]}
          labelRowsPerPage="ページごとの行："
          labelDisplayedRows={({ from, to, count }) => `${count}の${from}-${to}`}
        />
      </CardActions>
    </Card>
  );
};

SectionsTable.propTypes = {
  className: PropTypes.string
};

export default SectionsTable;
