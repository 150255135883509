import palette from 'theme/palette';
import blue from '@material-ui/core/colors/blue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import grey from '@material-ui/core/colors/grey';

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  cornerRadius: 0,
  tooltips: {
    enabled: false,
    // mode: 'index',
    // intersect: false,
    // borderWidth: 1,
    // borderColor: palette.divider,
    // backgroundColor: palette.white,
    // titleFontColor: palette.text.primary,
    // bodyFontColor: palette.text.secondary,
    // footerFontColor: palette.text.secondary,
    // callbacks: {
    //   label: function(tooltipItem, data) {
    //     var label = data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index] || '';
        
    //     if (label !== '') {
    //       return label.toLocaleString('ja-JP', {
    //         style: 'currency',
    //         currency: 'JPY',
    //       });
    //     }
    //   }
    // }
  },
  annotation: {
    annotations: [
      {
        display: 'auto',
        type: 'line',
        drawTime: 'afterDatasetsDraw',
        id: 'line-1',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 50,
        borderColor: blue[200],
        borderWidth: 1,
        label: {
          backgroundColor: blue[200],
          content: "50%",
          enabled: true
        }
      },
      {
        display: 'auto',
        type: 'line',
        drawTime: 'afterDatasetsDraw',
        id: 'line-2',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 100,
        borderColor: lightGreen[200],
        borderWidth: 1,
        label: {
          backgroundColor: lightGreen[200],
          content: "目標",
          enabled: true
        }
        }
    ]
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      offset: 1,
      textAlign: 'center',
      formatter: function(value, context) { 
        if (context.dataset.label !== '' && context.dataset.realProfitPercents !== undefined) { 
          return Math.round(context.dataset.realProfitPercents[context.dataIndex]) + '%\n' + context.dataset.label[context.dataIndex].toLocaleString('ja-JP', {style: 'currency',currency: 'JPY'}) + "\n粗利益";
        }
        else if (context.dataset.label !== '' && context.dataset.realInsalePercents !== undefined) {
            if (context.dataIndex === context.dataset.realInsalePercents.length-1) {
                return context.dataset.label[context.dataIndex].toLocaleString('ja-JP', {style: 'currency',currency: 'JPY'}) + "\n社内売上";
            }
            else {
                return Math.round(context.dataset.realInsalePercents[context.dataIndex]) + '%\n' + context.dataset.label[context.dataIndex].toLocaleString('ja-JP', {style: 'currency',currency: 'JPY'}) + "\n社内売上";
            }
        }
        else if (context.dataset.label !== '') {
          return Math.round(value) + '%\n' + context.dataset.label[context.dataIndex].toLocaleString('ja-JP', {style: 'currency',currency: 'JPY'}) + "\n売上";
        }
        else {
          return null;
        }
      },
      color: function(context) {
        if (context.dataset.realProfitPercents !== undefined) {
          return lightGreen[600];
        }
        else {
          return grey[600];
        }
      },
      font: {
        weight: 'bold',
        family: "Noto Sans JP",
        size: 10
      }
    }
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        ticks: {
          fontFamily: "Noto Sans JP",
          fontColor: grey[500],
          fontStyle: 'bold'
        },
        gridLines: {
          display: true,
          drawBorder: true
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: grey[500],
          fontStyle: 'bold',
          fontFamily: "Noto Sans JP",
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 180,
          callback: function (value) {
            return value + "%";
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        },
        scaleLabel : {
          display: true,
          labelString: 'パ－セント',
          fontColor: grey[500],
          fontStyle: 'bold',
          fontFamily: "Noto Sans JP",
        }
      }
    ]
  }
};