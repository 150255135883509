const types = [
    {
        key: 'member',
        value: 'メンバー'
    },
    {
        key: 'manager',
        value: 'マネージャー'
    },
    {
        key: 'admin',
        value: '管理者'
    }
]

export default types;