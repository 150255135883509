import React,{ useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 318,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const YearlyCompletedSales = props => {
  const { className, socket, ...rest } = props;

  const classes = useStyles();
  const [datas, setDatas] = useState({ datasets:[], labels:[] });
  const [title, setTitle] = useState('');

  const seasons = [
    {
      name: '第1QT',
      months: [12,1,2],
      yearly: false
    },
    {
      name: '第2QT',
      months: [3,4,5],
      yearly: false
    },
    {
      name: '第3QT',
      months: [6,7,8],
      yearly: false
    },
    {
      name: '第4QT',
      months: [9,10,11],
      yearly: true
    }
  ];

  var labels = [];
  var points = [];
  var colors = [];
  var borderColors = [];
  var maxPoints = [];
  var percents = [];
  var _isMounted = false;

  function getPercent(point,maxPoint)
  {
    return Math.floor(point/maxPoint*100);
  }

  function getLabels (data) {
    for (const tempData of data) {
      var index = labels.indexOf(tempData.section.name);

      if (index === -1) {
        labels.push(tempData.section.name);
        points.push(tempData.bill);
        maxPoints.push(tempData.point);
      }
      else {
        points[index] += tempData.bill;
        maxPoints[index] += tempData.point;
      }
    }

    labels.push("ALL");

    let calculateSum = function(arr){
      return arr.reduce(function(a,b){
        return a + b
      }, 0);
    };
    points.push(calculateSum(points));
    maxPoints.push(calculateSum(maxPoints));

    for (let i=0; i<points.length; i++) {
      percents.push(getPercent(points[i], maxPoints[i]));

      if (percents[i] < 50) {
        colors.push(orange[200]);
        borderColors.push(orange[400]);
      }
      else if (percents[i] < 100) {
        colors.push(blue[200]);
        borderColors.push(blue[400]);
      }
      else if (percents[i] >= 100) {
        colors.push(lightGreen[200]);
        borderColors.push(lightGreen[400]);
      }
    }

    for (let i=0; i<labels.length; i++) {
      labels[i] = [labels[i], "売上", maxPoints[i].toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})];
    }

    return labels;
  }

  useEffect(() => {
    _isMounted = true;

    const fetchData = async () => {
      let result = [];

      try {
        result = await axios(
          '/api/orders/seasonly/completed'
        );
      } catch (err) {
        result = [];
      }

      labels = [];
      points = [];
      colors = [];
      borderColors = [];
      maxPoints = [];
      percents = [];

      if (result.data !== undefined && result.data.data !== undefined) {
        return result.data.data;
      }
      else {
        return result;
      }
    }

    socket.on("change_data", function() {
      fetchData().then(function(data){
        if(_isMounted) {
          setDatas({
            labels: getLabels(data),
            datasets: [
              {
                label: points,
                data: percents,
                backgroundColor: colors,
                borderColor: borderColors,
                borderWidth: 1
              }
            ]
          })
        }
      });

      seasons.forEach(s => {
        if (s.months.indexOf(new Date().getMonth() + 1) !== -1) {
          if (s.yearly) {
            setTitle("当年");
          }
          else {
            setTitle(s.name);
          }
        }
      });
    });

    fetchData().then(function(data){
      if(_isMounted) {
        setDatas({
          labels: getLabels(data),
          datasets: [
            {
              label: points,
              data: percents,
              backgroundColor: colors,
              borderColor: borderColors,
              borderWidth: 1
            }
          ]
        })
      }
    });

    seasons.forEach(s => {
      if (s.months.indexOf(new Date().getMonth() + 1) !== -1) {
        if (s.yearly) {
          setTitle("当年");
        }
        else {
          setTitle(s.name);
        }
      }
    });

    return () => {
      _isMounted = false;
      socket.off("change_data");
    };
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={title + "完了実績"}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={datas}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
    </Card>
  );
};

YearlyCompletedSales.propTypes = {
  className: PropTypes.string,
  socket: PropTypes.object
};

export default YearlyCompletedSales;
