import React, { useState, useEffect, forwardRef } from 'react';

import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Snackbar,
  SnackbarContent,
  Switch
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

function PointIcon(props) {
  return (
    <SvgIcon {...props}
      viewBox="0,0,64,64"
      shapeRendering="geometricPrecision"
      fontSize="inherit">
      <g><path d="M36.135,23H27v9h9.135c2.566,0,4.648-1.932,4.648-4.499C40.783,24.932,38.701,23,36.135,23z" fill="#CC4521"/><path d="M32,1C14.88,1,1,14.879,1,32s13.88,31,31,31c17.119,0,31-13.879,31-31S49.119,1,32,1z M36.135,38H27v10h-6   V17h15.135c5.991,0,10.85,4.508,10.85,10.501C46.984,33.492,42.126,38,36.135,38z" fill="#D8532B"/><path d="M43.292,3.131c3.861,5.112,6.117,11.28,6.117,17.922c0,17.655-15.938,31.969-35.602,31.969   c-1.65,0-3.273-0.109-4.865-0.305C14.618,59.029,22.844,63,32,63c17.119,0,31-13.879,31-31C63,18.865,54.827,7.646,43.292,3.131z" fill="#CC4521"/><path d="M40.604,28.501c0.104-0.391,0.179-0.61,0.179-1.031c0-2.569-2.082-4.471-4.648-4.471H27v2h9.135   C38.279,25,40.069,26.524,40.604,28.501z" fill="#FC732D"/><g><path d="M36.135,38H27v3h9.135c5.991,0,10.85-5.047,10.85-11.038c0-0.411-0.028-1.009-0.071-1.406    C46.31,33.977,41.717,38,36.135,38z" fill="#FC732D"/><path d="M32,3.422c16.714,0,30.333,13.229,30.97,29.789C62.983,32.808,63,32.407,63,32C63,14.879,49.119,1,32,1    C14.88,1,1,14.879,1,32c0,0.407,0.016,0.808,0.03,1.211C1.667,16.651,15.285,3.422,32,3.422z" fill="#FC732D"/><rect fill="#FC732D" height="2" width="6" x="21" y="48"/></g></g>
    </SvgIcon>
  );
}

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  header: {
    backgroundColor: '#FFEB3B'
  },
  customHover: {
    "&.Mui-selected": {
      backgroundColor: "#FFF9C4 !important",
    },
    '&:hover': {
      backgroundColor: "#FFF9C4 !important",
    }
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function SnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const OrdersTable = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  var _isMounted = false;
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [page, setPage] = useState(0);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    _isMounted = true;
    const fetchData = async () => {
      const result = await axios(
        '/api/orders/all'
      );
      
      if(_isMounted) {
        setOrders(result.data.data);
      }
    }

    fetchData();

    return () => {
      _isMounted = false;
    };
  }, []);
  
  const handleChange = id => e => {
    const payload = {
      show: e.target.checked
    }

    try {
      const result = axios.put (
        '/api/order/show/' + id,
        payload
      );
    
      setOrders(orders.map(order => order._id === id ? {...order, show : e.target.checked} : order ));      
    } catch (error) {
      setCmessage("エラー!");
      handleErrorClick();
    }
  };

  const handleDoneChange = id => e => {
    const payload = {
      done: e.target.checked
    }

    try {
      const result = axios.put (
        '/api/order/done/' + id,
        payload
      );
    
      setOrders(orders.map(order => order._id === id ? {...order, done : e.target.checked} : order ));      
    } catch (error) {
      setCmessage("エラー!");
      handleErrorClick();
    }
  };

  const handleSelectAll = event => {
    let selectedOrders;

    if (event.target.checked) {
      selectedOrders = orders.map(order => order._id);
    } else {
      selectedOrders = [];
    }

    setSelectedOrders(selectedOrders);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrders.indexOf(id);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders, id);
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1)
      );
    }

    setSelectedOrders(newSelectedOrders);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const [eopen, setEopen] = useState(false);
  const [cmessage, setCmessage] = useState();

  function handleClick() {
    setOpen(true);
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  function handleErrorClick() {
    setEopen(true);
  }

  function handleErrorClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setEopen(false);
  }

  const handleDeleteClick = async () => {
    try {
      const result = await axios.delete (
        '/api/order',
        {
          params:{
            arr: selectedOrders
          }
        }
      );
    
      if (result.data.success && result.data.success !== undefined) {
        setOrders(
          orders.filter(order => selectedOrders.indexOf(order._id) === -1),
        );
        setSelectedOrders([]);

        setCmessage(result.data.message);
        handleClick();
      }
      else {
        setCmessage(result.data.message);
        handleErrorClick();
      }
    } catch (error) {
      setCmessage("案件削除されていません!");
      handleErrorClick();
    }
  };

  const numberFormat = (value) => new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY'
  }).format(value);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Toolbar
          className={clsx(classes.root, classes.header, {
            [classes.highlight]: selectedOrders.length > 0,
          })}
        >
          <div className={classes.title}>
            {selectedOrders.length > 0 ? (
              <Typography color="inherit" variant="subtitle1">
                {selectedOrders.length} 選択された
              </Typography>
            ) : (
              <Typography variant="h5" id="tableTitle">
                受注
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {selectedOrders.length > 0 ? (
              <Tooltip title="削除する">
                <IconButton 
                  aria-label="削除する"
                  onClick={handleDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="">
                <IconButton aria-label="">
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Toolbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <SnackbarContentWrapper
            onClose={handleClose}
            variant="success"
            message={cmessage}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={eopen}
          autoHideDuration={6000}
          onClose={handleErrorClose}
        >
          <SnackbarContentWrapper
            onClose={handleErrorClose}
            variant="error"
            message={cmessage}
          />
        </Snackbar>
        <div className={classes.inner}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  {/* <Checkbox
                    checked={selectedOrders.length === orders.length}
                    color="primary"
                    indeterminate={
                      selectedOrders.length > 0 &&
                      selectedOrders.length < orders.length
                    }
                    onChange={handleSelectAll}
                  /> */}
                </TableCell>
                <TableCell>名前</TableCell>
                <TableCell>お客様</TableCell>
                <TableCell>案件</TableCell>
                <TableCell>金銭</TableCell>
                <TableCell>粗利益</TableCell>
                <TableCell>セクション</TableCell>
                <TableCell>作成日</TableCell>
                <TableCell>月日</TableCell>
                <TableCell>表示</TableCell>
                <TableCell>完了</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.length > 0 ? orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(order => (
                <TableRow
                  className={clsx(classes.tableRow, classes.customHover)}
                  hover
                  key={order._id}
                  selected={selectedOrders.indexOf(order._id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedOrders.indexOf(order._id) !== -1}
                      color="primary"
                      onChange={event => handleSelectOne(event, order._id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell><b>{order.name.lastname}</b></TableCell>
                  <TableCell>{order.client}</TableCell>
                  <TableCell>{order.order}</TableCell>
                  <TableCell>
                    <b>
                      {numberFormat(order.point)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {numberFormat(order.profit)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <span
                      style={order.section.color ? {color: "#" + order.section.color} : {color: "#689f38"}}>
                      <b>
                        {order.section.name}
                      </b>
                    </span>
                  </TableCell>
                  <TableCell>
                    {moment(order.createdAt).format('YYYY年MM月DD日 HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {moment(order.date).format('YYYY年MM月DD日 HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={order.show}
                      onChange={handleChange(order._id)}
                      value="true"
                      edge="start"
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={order.done}
                      onChange={handleDoneChange(order._id)}
                      value="false"
                      edge="start"
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="outlined"
                      component={CustomRouterLink}
                      to={`/order-edit/${order._id}`}
                    >
                      編集<br/>する
                    </Button>
                  </TableCell>
                </TableRow>
              )) : <TableRow/>}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={orders.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 12, 20]}
          labelRowsPerPage="ページごとの行："
          labelDisplayedRows={({ from, to, count }) => `${count}の${from}-${to}`}
        />
      </CardActions>
    </Card>
  );
};

OrdersTable.propTypes = {
  className: PropTypes.string
};

export default OrdersTable;
