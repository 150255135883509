import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Box } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 200,
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  lineTitle: {
    textAlign: 'center'
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();
  const [user, setUser] = useState({
    name: '',
    section: '',
    type: '',
  });

  const pages = [
    {
      permission: ['admin','manager','member'],
      title: 'ダッシュボード',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      permission: ['admin','manager','member'],
      title: '受注速報',
      href: '/current',
      icon: <AssignmentLateIcon />
    },
    {
      permission: ['admin','manager','member'],
      title: '完了速報',
      href: '/completed',
      icon: <AssignmentTurnedInIcon />
    },
    {
      permission: ['admin','manager'],
      id: 2,
      title: '受注',
      href: '/orders',
      icon: <AssignmentIcon />
    },
    {
      permission: ['admin'],
      id: 3,
      title: 'ユーザー',
      href: '/users',
      icon: <PersonIcon />
    },
    {
      permission: ['admin'],
      id: 4,
      title: 'セクション',
      href: '/sections',
      icon: <PeopleIcon />
    }
  ];

  useEffect(() => {
    let token = Cookies.get('token');

    if (token) {
      let tokenData = jwt_decode(token);
      setUser({
        id: tokenData.id,
        name: tokenData.name,
        section: tokenData.section,
        type: tokenData.type,
        line: tokenData.line
      });
    }
  }, []);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile user={user}/>
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          user={user}
        />
        <Divider className={classes.divider} />
        <Box className={classes.lineBox} component="span" m={1}>
          <h6 className={classes.lineTitle}>LINEボット：ミライク</h6>
          <img
            alt="Line Login"
            src="/images/line/qr.png"
          />
        </Box>
        {}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
