import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'

// import * as serviceWorker from './serviceWorker';
import App from './App';

ReactDOM.render(<HashRouter><App /></HashRouter>, document.getElementById('root'));

// serviceWorker.register();
