import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import withAuth from './withAuth';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Completed as CompletedView,
  Current as CurrentView,
  OrderList as OrderListView,
  Order as OrderView,
  OrderEdit as OrderEditView,
  SignIn as SignInView,
  UserList as UserListView,
  User as UserView,
  UserEdit as UserEditView,
  SectionList as SectionListView,
  Section as SectionView,
  SectionEdit as SectionEditView,
  Line as LineView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/current"
      />
      <RouteWithLayout
        component={withAuth(DashboardView, 'dashboard')}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={withAuth(CompletedView, 'completed')}
        exact
        layout={MainLayout}
        path="/completed"
      />
      <RouteWithLayout
        component={withAuth(CurrentView, 'current')}
        exact
        layout={MainLayout}
        path="/current"
      />
      <RouteWithLayout
        component={withAuth(SignInView, 'signIn')}
        exact
        layout={MinimalLayout}
        path="/login"
      />      
      <RouteWithLayout
        component={withAuth(OrderListView, 'orderList')}
        exact
        layout={MainLayout}
        path="/orders"
      />
      <RouteWithLayout
        component={withAuth(OrderView, 'order')}
        exact
        layout={MainLayout}
        path="/order"
      />
      <RouteWithLayout
        component={withAuth(OrderEditView, 'orderEdit')}
        exact
        layout={MainLayout}
        path="/order-edit/:id"
      />
      <RouteWithLayout
        component={withAuth(UserListView, 'userList')}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={withAuth(UserView, 'user')}
        exact
        layout={MainLayout}
        path="/user"
      />
      <RouteWithLayout
        component={withAuth(UserEditView, 'userEdit')}
        exact
        layout={MainLayout}
        path="/user-edit/:id"
      />
      <RouteWithLayout
        component={withAuth(SectionListView, 'sectionList')}
        exact
        layout={MainLayout}
        path="/sections"
      />
      <RouteWithLayout
        component={withAuth(SectionView, 'section')}
        exact
        layout={MainLayout}
        path="/section"
      />
      <RouteWithLayout
        component={withAuth(SectionEditView, 'sectionEdit')}
        exact
        layout={MainLayout}
        path="/section-edit/:id"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={withAuth(LineView, 'line')}
        exact
        layout={MainLayout}
        path="/line*"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
