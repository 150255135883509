import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Fade } from '@material-ui/core';

import { OrderDetails } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Order = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Fade
          in={true}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <OrderDetails/>
          </Grid>
        </Fade>
      </Grid>
    </div>
  );
};

export default Order;
