import React, { useState, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close'
import { withRouter } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import NumberFormat from 'react-number-format';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker ,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  IconButton,
  Button,
  TextField,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';
import KeyboardDateInput from '@material-ui/pickers/_shared/KeyboardDateInput';

const useStyles = makeStyles(theme => ({
  root: {},
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

function SnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      prefix="¥"
      allowLeadingZeros="false"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const OrderDetails = props => {
  const { className, staticContext, ...rest } = props;  
  const classes = useStyles();
  var _isMounted = false;

  const [values, setValues] = useState({
    name: '',
    client: '',
    order: '',
    section: '',
    point: 0,
    profit: 0,
    bill: 0,
    sections: [],
    names: [],
    insales: []
  });
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedFinishDate, handleFinishDateChange] = useState(new Date());

  useEffect(() => {
    _isMounted = true;
    const fetchData = () => {
      axios(
        '/api/sections'
      ).then(function(sectionsResult){
        axios(
          '/api/names'
        ).then(function(namesResult){
          if(_isMounted) {
            let tmpInsales = [];
            for (let i = 0; i < sectionsResult.data.data.length; i++) {
              if (sectionsResult.data.data[i]._id !== namesResult.data.data[0].section._id) {
                tmpInsales.push({
                  id: sectionsResult.data.data[i]._id,
                  name: sectionsResult.data.data[i].name,
                  amount: 0 
                });
              }
            }

            setValues({
              ...values,
              name: namesResult.data.data[0]._id,
              names: namesResult.data.data,
              sections: sectionsResult.data.data,
              section: namesResult.data.data[0].section._id, 
              point: namesResult.data.data[0].section.dPoint, 
              profit: namesResult.data.data[0].section.dPoint,
              insales: tmpInsales
            });
          }
        });
      });
    }

    fetchData();

    return () => {
      _isMounted = false;
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [eopen, setEopen] = useState(false);
  const [cmessage, setCmessage] = useState();

  function handleClick() {
    setOpen(true);
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  function handleErrorClick() {
    setEopen(true);
  }

  function handleErrorClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setEopen(false);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlePointChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value
    });
  };

  const handleSectionsChange = event => {
    let tmpInsales = [];
    for (let i = 0; i < values.sections.length; i++) {
      if (values.sections[i]._id !== event.target.value) {
        tmpInsales.push({
          id: values.sections[i]._id,
          name: values.sections[i].name,
          amount: 0 
        });
      }
    }
    
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      point: values.sections.find(function(n){ return n._id === event.target.value }).dPoint,
      profit: values.sections.find(function(n){ return n._id === event.target.value }).dPoint,
      insales: tmpInsales
    });
  };

  const handleNamesChange = event => {
    let tmpInsales = [];
    let sectionId = values.names.find(function(n){ return n._id === event.target.value }).section._id;
    for (let i = 0; i < values.sections.length; i++) {
      if (values.sections[i]._id !== sectionId) {
        tmpInsales.push({
          id: values.sections[i]._id,
          name: values.sections[i].name,
          amount: 0 
        });
      }
    }

    setValues({
      ...values,
      [event.target.name]: event.target.value,
      section: values.names.find(function(n){ return n._id === event.target.value }).section._id,
      point: values.names.find(function(n){ return n._id === event.target.value }).section.dPoint,
      profit: values.names.find(function(n){ return n._id === event.target.value }).section.dPoint,
      insales: tmpInsales
    });
  };

  const handleInsaleChange = id => event => {
    const newInsales = values.insales.map(insale => {
      if (id !== insale.id) return insale;
      return { ...insale, amount: event.target.value };
    });

    setValues({
      ...values,
      insales: newInsales
    });
  };

  const handleCreateOrder = async () => {    
    const payload = {
      name: values.name,
      client: values.client,
      order: values.order,
      section: values.section,
      point: values.point,
      profit: values.profit,
      bill: values.bill,
      date: selectedDate,
      finishDate: selectedFinishDate,
      insales: values.insales
    }

    try {
      const result = await axios.post (
        '/api/order',
        payload
      );
    
      if (result.data.success && result.data.success !== undefined) {
        let tmpInsales = [];
        for (let i = 0; i < values.insales.length; i++) {
          tmpInsales.push({
            id: values.insales[i].id,
            name: values.insales[i].name,
            amount: 0
          });
        }

        setValues({
          ...values,
          client: '',
          order: '',
          point: values.names.find(function(n){ return n._id === values.name }).section.dPoint,
          profit: values.names.find(function(n){ return n._id === values.name }).section.dPoint,
          insales: tmpInsales
        });

        handleDateChange(new Date());
        handleFinishDateChange(new Date());

        setCmessage(result.data.message);
        handleClick();
      }
      else {
        setCmessage(result.data.message);
        handleErrorClick();
      }
    } catch (error) {
      setCmessage("案件作成されていません!");
      handleErrorClick();
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader=""
          title="新案件"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="名前"
                margin="dense"
                name="name"
                onChange={handleNamesChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={values.name}
              >
                {values.names.map(option => (
                  <option
                    key={option.lastname}
                    value={option._id}
                  >
                    {option.lastname}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="セクション"
                margin="dense"
                name="section"
                onChange={handleSectionsChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={values.section}
              >
                {values.sections.map(option => (
                  <option
                    key={option.name}
                    value={option._id}
                  >
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="お客様"
                margin="dense"
                name="client"
                onChange={handleChange}
                required
                value={values.client}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="金銭"
                margin="dense"
                name="point"
                value={values.point}
                variant="outlined"
                onChange={handlePointChange('point')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="案件"
                margin="dense"
                name="order"
                onChange={handleChange}
                required
                value={values.order}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="粗利益"
                margin="dense"
                name="profit"
                value={values.profit}
                variant="outlined"
                onChange={handlePointChange('profit')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  value={selectedDate} 
                  onChange={handleDateChange} 
                  format="YYYY/MM/DD" 
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="日時"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="請求金額"
                margin="dense"
                name="bill"
                value={values.bill}
                variant="outlined"
                onChange={handlePointChange('bill')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  value={selectedFinishDate} 
                  onChange={handleFinishDateChange} 
                  format="YYYY/MM/DD" 
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="終了日"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Card
                {...rest}
                className={clsx(classes.root, className)}
              >
                <CardHeader
                  subheader=""
                  title="社内受注"
                />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    {values.insales.map(insale => (
                      <Grid
                        item
                        md={3}
                        xs={12}
                        key={insale.id}
                      >
                        <TextField
                          fullWidth
                          label={insale.name}
                          margin="dense"
                          name={insale.id}
                          value={insale.amount}
                          variant="outlined"
                          onChange={handleInsaleChange(insale.id)}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component={CustomRouterLink}
            to="/orders"
            >
              <CloseIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
              閉じる
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleCreateOrder}
            >
              <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
              保存
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <SnackbarContentWrapper
              onClose={handleClose}
              variant="success"
              message={cmessage}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={eopen}
            autoHideDuration={6000}
            onClose={handleErrorClose}
          >
            <SnackbarContentWrapper
              onClose={handleErrorClose}
              variant="error"
              message={cmessage}
            />
          </Snackbar>
        </CardActions>
      </form>
    </Card>
  );
};

OrderDetails.propTypes = {
  className: PropTypes.string
};

export default withRouter(OrderDetails);
