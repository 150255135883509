import React, {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { line } from 'helpers'
import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  line: {
    marginTop: theme.spacing(3)
  }
}));

const Profile = props => {
  const { className, user, ...rest } = props;
  const classes = useStyles();
  const lineUrl = "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=" + 
    line.client_id + "&redirect_uri=" + line.redirect_uri + "&state=" + line.state + "&scope=" + line.scope + "&bot_prompt=aggressive";

  const lineToken = Cookies.get('line_token');

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src='/images/avatars/avatar.png'
        to="/"
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">{user.section}</Typography>
      { lineToken || user.line !== '' ? "" :
        <a className={classes.line} href={lineUrl}>
          <img
            alt="Line Login"
            src="/images/line/button_login.png"
          />
        </a>
      }
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default Profile;
