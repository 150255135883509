import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import lightGreen from '@material-ui/core/colors/lightGreen';
import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 318,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const MonthlySales = props => {
  const { className, socket, ...rest } = props;

  const classes = useStyles();
  const month = new Date().getMonth() + 1;
  const [datas, setDatas] = useState({ datasets:[], labels:[] });
  
  var labels = [];
  var points = [];
  var colors = [];
  var borderColors = [];
  var maxPoints = [];
  var percents = [];
  var profits = [];
  var drawProfitPercents = [];
  var realProfitPercents = [];
  var insales = [];
  var insalePercents = [];
  var drawInsalePercents = [];
  var _isMounted = false;

  function getPercent(point, maxPoint)
  {
    return Math.floor(point/maxPoint*100);
  }

  function getRealProfitPercent(profit, point)
  {
    return Math.floor(profit/point*100);
  }

  function getDrawProfitPercent(profit, point, percent)
  {
    return Math.floor(profit/point*percent);
  }

  function getInsalePercent(insale, point)
  {
    return Math.floor(insale/point*100);
  }

  function getDrawInsalePercent(insale, point, percent)
  {
    return Math.floor(insale/point*percent);
  }

  function getLabels (data) {
    if (data.data !== undefined) {
        for (const tempData of data.data) {
            var index = labels.indexOf(tempData.section.name);

            if (tempData.section.mPoint === 0 && tempData.section["target" + (month)] === 0) continue;

            if (index === -1) {
                labels.push(tempData.section.name);
                points.push(tempData.point);
                profits.push(tempData.profit);
                insales.push(0);

                if (tempData.section["target" + (month)] !== 0) {
                    maxPoints.push(tempData.section["target" + (month)]);
                }
                else {
                    maxPoints.push(tempData.section.mPoint);
                }
            }
            else {
                points[index] += tempData.point;
                profits[index] += tempData.profit;
            }
        }
    }

    if (data.insales !== undefined) {
      for (const tempInsale of data.insales) {
        var index = labels.indexOf(tempInsale.sectionId.name);
        
        if (index === -1) {
            labels.push(tempInsale.sectionId.name);
            points.push(tempInsale.amount);
            profits.push(0);
            insales.push(tempInsale.amount);
            maxPoints.push(tempInsale.sectionId.mPoint);    
        }
        else {
            insales[index] += tempInsale.amount;
            points[index] += tempInsale.amount;
        }
      }
    }

    labels.push("ALL");

    let calculateSum = function(arr){
      return arr.reduce(function(a,b){
        return a + b
      }, 0);
    };
    points.push(calculateSum(points)-calculateSum(insales));
    profits.push(calculateSum(profits));
    maxPoints.push(calculateSum(maxPoints));
    insales.push(calculateSum(insales));

    for (let i=0; i<points.length; i++) {
      percents.push(getPercent(points[i], maxPoints[i]));
      realProfitPercents.push(getRealProfitPercent(profits[i], points[i]));
      drawProfitPercents.push(getDrawProfitPercent(profits[i], points[i], percents[i]));
      insalePercents.push(getInsalePercent(insales[i], points[i]));
      drawInsalePercents.push(getDrawInsalePercent(insales[i], points[i], percents[i]));

      if (percents[i] < 50) {
        colors.push(orange[200]);
        borderColors.push(orange[400]);
      }
      else if (percents[i] < 100) {
        colors.push(blue[200]);
        borderColors.push(blue[400]);
      }
      else if (percents[i] >= 100) {
        colors.push(lightGreen[200]);
        borderColors.push(lightGreen[400]);
      }
    }

    for (let i=0; i<labels.length; i++) {
      labels[i] = [labels[i], "目標", maxPoints[i].toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})];
    }

    return labels;
  }

  useEffect(() => {
    _isMounted = true;

    const fetchData = async () => {
      let result = [];

      try {
        result = await axios(
          '/api/orders/monthly'
        );
      } catch (err) {
        result = [];
      }
      
      labels = [];
      points = [];
      colors = [];
      borderColors = [];
      maxPoints = [];
      percents = [];
      profits = [];
      drawProfitPercents = [];
      realProfitPercents = [];
      insales = [];
      insalePercents = [];
      drawInsalePercents = [];

      if (result.data !== undefined && result.data.data !== undefined) {
        return result.data;
      }
      else {
        return result;
      }
    }

    socket.on("change_data", function() {
      if(_isMounted) {
        fetchData().then(function(data){
            setDatas({
                labels: getLabels(data),
                datasets: [
                  {
                    label: points,
                    data: percents,
                    backgroundColor: colors,
                    borderColor: borderColors,
                    borderWidth: 1
                  },
                  {
                    label: profits,
                    data: drawProfitPercents,
                    borderColor: lightGreen[200],
                    borderWidth: 1,
                    backgroundColor: lightGreen[50],
                    realProfitPercents: realProfitPercents
                  },
                  {
                    label: insales,
                    data: drawInsalePercents,
                    borderColor: grey[400],
                    borderWidth: 1,
                    backgroundColor: grey[200],
                    realInsalePercents: insalePercents
                  }
                ]
            })
        });
      }
    });

    fetchData().then(function(data){
      if(_isMounted) {
        setDatas({
          labels: getLabels(data),
          datasets: [
            {
              label: points,
              data: percents,
              backgroundColor: colors,
              borderColor: borderColors,
              borderWidth: 1
            },
            {
              label: profits,
              data: drawProfitPercents,
              borderColor: lightGreen[200],
              borderWidth: 1,
              backgroundColor: lightGreen[50],
              realProfitPercents: realProfitPercents
            },
            {
              label: insales,
              data: drawInsalePercents,
              borderColor: grey[400],
              borderWidth: 1,
              backgroundColor: grey[200],
              realInsalePercents: insalePercents
            }
          ]
        })
      }
    });

    return () => {
      _isMounted = false;
      socket.off("change_data");
    };
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={
        //   <Button
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days <ArrowDropDownIcon />
        //   </Button>
        // }
        title={"当月受注実績"}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={datas}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      {/* <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Overview <ArrowRightIcon />
        </Button>
      </CardActions> */}
    </Card>
  );
};

MonthlySales.propTypes = {
  className: PropTypes.string,
  socket: PropTypes.object
};

export default MonthlySales;
