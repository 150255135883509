import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
import { options } from './chart';
import { max } from 'moment';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 318,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const MonthlyCompletedSales = props => {
  const { className, socket, ...rest } = props;

  const classes = useStyles();
  const [datas, setDatas] = useState({ datasets:[], labels:[] });
  
  var labels = [];
  var points = [];
  var _isMounted = false;

  function getLabels (data) {
    for (const tempData of data) {
      var index = labels.indexOf(tempData.section.name);

      if (index === -1) {
        labels.push(tempData.section.name);
        points.push(tempData.bill);
      }
      else {
        points[index] += tempData.bill;
      }
    }

    labels.push("ALL");

    let calculateSum = function(arr){
      return arr.reduce(function(a,b){
        return a + b
      }, 0);
    };
    points.push(calculateSum(points));

    return labels;
  }

  useEffect(() => {
    _isMounted = true;

    const fetchData = async () => {
      let result = [];

      try {
        result = await axios(
          '/api/orders/monthly/completed'
        );
      } catch (err) {
        result = [];
      }
      
      labels = [];
      points = [];

      if (result.data !== undefined && result.data.data !== undefined) {
        return result.data.data;
      }
      else {
        return result;
      }
    }

    socket.on("change_data", function() {
      if(_isMounted) {
        fetchData().then(function(data){
          setDatas({
            labels: getLabels(data),
            datasets: [
              {
                label: points,
                data: points,
                backgroundColor: orange[200],
                borderColor: orange[400],
                borderWidth: 1
              }
            ]
          })
        });
      }
    });

    fetchData().then(function(data){
      if(_isMounted) {
        setDatas({
          labels: getLabels(data),
          datasets: [
            {
              label: points,
              data: points,
              backgroundColor: orange[200],
              borderColor: orange[400],
              borderWidth: 1
            }
          ]
        })
      }
    });

    return () => {
      _isMounted = false;
      socket.off("change_data");
    };
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={"当月完了実績"}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={datas}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      {}
    </Card>
  );
};

MonthlyCompletedSales.propTypes = {
  className: PropTypes.string,
  socket: PropTypes.object
};

export default MonthlyCompletedSales;
