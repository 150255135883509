import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Fade } from '@material-ui/core';

import { OrdersToolbar, OrdersTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const OrderList = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <OrdersToolbar />
      <Fade
        in={true}
      >
        <div className={classes.content}>
          <OrdersTable />
        </div>
      </Fade>
    </div>
  );
};

export default OrderList;