import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Fade } from '@material-ui/core';

import {
  LatestOrders
} from './components';

import socketIOClient from "socket.io-client";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
}));

const socket = socketIOClient('/');

const Current = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        item={true}
        direction='row'
        justify="center"
        alignItems="baseline"
        spacing={2}
        xs={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Fade
        in={true}
        >
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
            >
                <LatestOrders socket={socket}/>
            </Grid>
        </Fade>
      </Grid>
    </div>
  );
};

export default Current;
