import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { types } from 'helpers';

export default function withAuth(ComponentToProtect, compName) {  
  return class extends Component {
    _isMounted = false;
    
    managerPermission = [
      'dashboard',
      'completed',
      'orderList',
      'order',
      'orderEdit',
      'line'
    ];

    memberPermission = [
      'dashboard',
      'completed'
    ];
    
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        permRedirect: false
      };
    }

    checkPermission() {
      let token = Cookies.get('token');

      if (token) {
        let tokenData = jwt_decode(token);
        
        switch(tokenData.type) {
          case 'admin':
            this.setState({ 
              loading: false,
            });
            break;
          case 'manager':
            if (this.managerPermission.indexOf(compName) !== -1) {
              this.setState({
                loading: false,
              });
            }
            else {
              this.setState({
                loading: false,
                permRedirect: true
              });
            }
            break;
          case 'member':
              if (this.memberPermission.indexOf(compName) !== -1) {
                this.setState({
                  loading: false,
                });
              }
              else {
                this.setState({
                  loading: false,
                  permRedirect: true
                });
              }
            break;
        }
      }
      else {
        this.setState({ loading: false, redirect: true });
      }
    }

    componentDidMount() {
      this._isMounted = true;
      fetch('/api/checkToken')
      .then(res => {
        if (res.status === 200) {
          if (this._isMounted) {
            if (compName === 'signIn') {
              this.setState({
                loading: false,
                permRedirect: true
              });
            }
            else {
              this.checkPermission()
            }
          }
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch(err => {
        if (this._isMounted) {
          if (compName === 'signIn') {
            this.setState({ loading: false });
          }
          else {
            this.setState({ loading: false, redirect: true });
          }
        }
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }
    
    render() {
      const { loading, redirect, permRedirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      if (permRedirect) {
        return <Redirect to="/" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props}/>
        </React.Fragment>
      );
    }
  }
}