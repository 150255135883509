import palette from 'theme/palette';
import blue from '@material-ui/core/colors/blue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import grey from '@material-ui/core/colors/grey';

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  cornerRadius: 0,
  tooltips: {
    enabled: false
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      offset: 1,
      textAlign: 'center',
      formatter: function(value, context) { 
        return context.dataset.label[context.dataIndex].toLocaleString('ja-JP', {style: 'currency',currency: 'JPY'}) + "\n請求金額";
      },
      color: function(context) {
        if (context.dataset.realProfitPercents !== undefined) {
          return lightGreen[600];
        }
        else {
          return grey[600];
        }
      },
      font: {
        weight: 'bold',
        family: "Noto Sans JP",
        size: 10
      }
    }
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barPercentage: 0.55,
        categoryPercentage: 0.95,
        ticks: {
          fontFamily: "Noto Sans JP",
          fontColor: grey[500],
          fontStyle: 'bold'
        },
        gridLines: {
          display: true,
          drawBorder: true
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: grey[500],
          fontStyle: 'bold',
          fontFamily: "Noto Sans JP",
          callback: function (value) {
            return value.toLocaleString('ja-JP', {style: 'currency',currency: 'JPY'});
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        },
        scaleLabel : {
          display: true,
          labelString: '請求金額',
          fontColor: grey[500],
          fontFamily: "Noto Sans JP",
        }
      }
    ]
  }
};
